<template>
<div id="ListeningExamStart">  
  <Header/>
  <body class="">
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a class="navbar-brand font-weight-bolder me-0 col-1" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-10 text-center" rel="tooltip" data-placement="bottom" id="exam_paper_title">
          {{examPaperName}}
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-1" >

        </a> 
      </div>
    </nav>  

    <div class="nicolespace"></div>
    <div class="container mt-2 mt-md-3 mb-4 mb-md-5">

      <!-- 題型說明 -->
      <div class="bigbox3 text-left">  
        <div class="text-s text-dark" style="">{{examPaperDescription}}</div>
      </div>

      <!--Exam Group 圖片-->
      <div v-show="currExam.exam_style != undefined && (currExam.exam_style == 'G3' || currExam.exam_style == 'G4' || currExam.exam_style == 'G5' || currExam.exam_style == 'P1' || currExam.exam_style == 'P2' || currExam.exam_style == 'P3')">
        <div class="text-center mt-3">  
            <img v-if="config && config.old_images && config.old_images=='Y'" v-real-img="'data:image/jpeg;base64,'+examGroupImage" src="assets/img/noback.png" class="green_test_pic rounded-3 " style="object-fit: contain;">
            <img v-if="config && config.old_images && config.old_images=='N'" v-real-img="examGroupImage" src="assets/img/noback.png" class="green_test_pic rounded-3 " style="object-fit: contain;">
        </div>  
      </div>

      <!--Exam Group 音檔-->
      <div class="my-2" v-show="currExam.exam_style != undefined && (currExam.exam_style == 'G1' || currExam.exam_style == 'G2' || currExam.exam_style == 'G3' || currExam.exam_style == 'G4' || currExam.exam_style == 'G5' || currExam.exam_style == 'P1' || currExam.exam_style == 'P2' || currExam.exam_style == 'P4' || currExam.exam_style == 'P6' || currExam.exam_style == 'Q1' || currExam.exam_style == 'Q2' || currExam.exam_style == 'Q3' || currExam.exam_style == 'Q4')">
        <div class="text-center" >
          <img :src="masterAudioPic ? 'assets/img/play.png' : 'assets/img/play_nostar.png' " class="avatar avatar-l" @click="startPlayVoice(currExam.exam_group.audio_url_m3u8, $event.target)">
        </div>
      </div>

      <!--Exam Group 文字-->
      <div class="bigbox3 " v-show="currExam.exam_style != undefined &&  (currExam.exam_style == 'G1' || currExam.exam_style == 'G2' || currExam.exam_style == 'G5' || currExam.exam_style == 'P1' || currExam.exam_style == 'P3' || currExam.exam_style == 'P5' || currExam.exam_style == 'P6' || currExam.exam_style == 'Q1' || currExam.exam_style == 'Q2' || currExam.exam_style == 'Q3' || currExam.exam_style == 'Q6')">
        <!-- eslint-disable vue/no-v-html -->
          <div class="word bigbox3 pt-2 pb-1 ck-content nicole13rem nweight5 lht2" v-html="examGroupName" v-if="currExam.exam_list != undefined && currExam.exam_list.length > 0 && currExam.exam_list[0].exam.eetid == 'sentence'"></div>
          <div class="" v-html="examGroupName" v-if="currExam.exam_list != undefined && currExam.exam_list.length > 0 && currExam.exam_list[0].exam.eetid == 'preview'"></div>
          <!--eslint-enable-->
      </div>

      <!-- Exam -->
      <div v-if="currExam.exam_mode == 1">
        <div class="exam" :id="(currExam.exam_list.length > 0 && currExam.exam_list[0].exam != undefined && currExam.exam_list[0].exam.eid  != undefined) ? currExam.exam_list[0].exam.eid : ''" data="N">
          <!-- 選項 -->
          <div v-if="examItemStyle=='text'">
            <div class="px-3">  
              <div class="text-center choose_mt">  
                <div class="position-relative chooise_mb" v-for="(item, index) in currExam.exam_list[0].item" :key='item.eiid'>
                  <div class="badge badge-secondary rounded-3 choose-btn text-simbold chooise text-wrap text-left" 
                      @click="OnItemClick(item.eiid ,item.eid , currExam.exam_list[0].exam.etid)" 
                      :id="'text'+item.eiid">
                    <div class="d-flex"> 
                      <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="index==0">A. </span> 
                      <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="index==1">B. </span> 
                      <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="index==2">C. </span> 
                      <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="index==3">D. </span> 
                      <!-- eslint-disable vue/no-v-html --> 
                      <div class="text-mm text-simbold lh18 contentbox" v-html="item.name"></div>
                      <!--eslint-enable-->
                    </div> 
                  </div>
                  <img src="assets/img/img008.png" class="avatar avatar-check rounded-0 position-absolute topdowncenter ms-2 hide" :id="'ok'+item.eiid">
                  <img src="assets/img/ic_test_fail.png" class="avatar avatar-check rounded-0 position-absolute topdowncenter ms-2 hide" :id="'fail'+item.eiid">
                </div>  
              </div>   
            </div>
          </div>
          <div v-if="examItemStyle=='image'">
            <div class=" mt-3">  
              <div class="text-center picbox mx-auto mt-3 mt-md-4">
                <div class="w-100  mb-3 mb-md-4"> 
                  <div class="nicolerow" v-for="(item) in currExam.exam_list[0].item" :key='item.eiid'> 
                    <div v-if="config && config.old_images && config.old_images=='Y'" class="w-70 rounded-3 text-right examItemImage greentestpicl  mx-auto mb-3 pe-1"
                        :style="item.image_url.indexOf('background-image') > -1 ? item.image_url : (item.image_url != '' ? 'background-image: url(data:image/jpeg;base64,'+item.image_url+');' : 'background-image: url(assets/img/noback.png);')"
                        @data-url="item.eiid"
                        @click="OnItemClick(item.eiid ,item.eid , currExam.exam_list[0].exam.etid)" 
                        :id="'text'+item.eiid" >
                      <img src="assets/img/white_yes.png" class="avatar avatar-check rounded-0 hide" :id="'ok'+item.eiid"> 
                      <img src="assets/img/white_no.png" class="avatar avatar-check rounded-0 hide" :id="'fail'+item.eiid">
                    </div>
                    <div v-if="config && config.old_images && config.old_images=='N'" class="w-70 rounded-3 text-right examItemImage greentestpicl  mx-auto mb-3 pe-1"
                        :style="item.image_url.indexOf('background-image') > -1 ? item.image_url : (item.image_url != '' ? 'background-image: url('+item.image_url+');' : 'background-image: url(assets/img/noback.png);')"
                        @data-url="item.eiid"
                        @click="OnItemClick(item.eiid ,item.eid , currExam.exam_list[0].exam.etid)" 
                        :id="'text'+item.eiid" >
                      <img src="assets/img/white_yes.png" class="avatar avatar-check rounded-0 hide" :id="'ok'+item.eiid"> 
                      <img src="assets/img/white_no.png" class="avatar avatar-check rounded-0 hide" :id="'fail'+item.eiid">
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>   
          <!-- Exam Concept -->
          <div class="mt-3 bigbox3" v-show="isShowConcept">
            <h5 class="text-dark text-bold  mt-2 mb-3 text-mm">題目解析</h5>
            <div class="d-flex mb-2"> 
              <!-- eslint-disable vue/no-v-html -->
              <div class="text-s text-dark" v-html="(currExam.exam_list != undefined && currExam.exam_list.length > 0 && currExam.exam_list[0].concept != undefined && currExam.exam_list[0].concept.length > 0 )? currExam.exam_list[0].concept[0].name : ''"></div>
              <!--eslint-enable-->
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="currExam.exam_mode == 2">
        <div class="exam" v-for="(item, index) in currExam.exam_list" :key="item.exam.eid" :id="(item.exam != undefined && item.exam.eid  != undefined) ? item.exam.eid : ''" data="N">
          <!-- Exam 題號 -->
          <div class="d-flex mt-3 "> 
            <span class="titlebox text-simbold text-dark text-simbold lh18">Q{{index+1}}.</span>
          </div> 
          <!-- Exam 音檔 -->
          <div class="text-center" v-if="item.exam.esid=='Q3'">
            <img :src="currentAudio==index ? 'assets/img/play.png' : 'assets/img/play_nostar.png'" class="avatar avatar-l" @click="startPlayVoice(item.exam.audio_url_m3u8, $event.target)">
          </div>
          <!-- Exam 文字 -->
          <div class="d-flex mt-3 "> 
            <span class="titlebox text-simbold text-dark text-simbold lh18" style="visibility: hidden;">Q{{index+1}}.</span>
            <!-- eslint-disable vue/no-v-html --> 
            <div class="text-s text-simbold text-dark contentbox lh18 ck-content " v-html="item.exam.name"></div>
            <!--eslint-enable-->
          </div>           
          <!-- Exam 圖片 -->

          <!-- 選項 -->
          <div v-if="item.exam.esid=='P4' || item.exam.esid=='P5' || item.exam.esid=='P6'">
            <div class=" mt-3">  
              <div class="text-center picbox mx-auto mt-3 mt-md-4">   
                <div class="w-100  mb-3 mb-md-4"> 
                  <div class="nicolerow" v-for="(examItem) in item.item" :key='examItem.eiid'> 
                    <div v-if="config && config.old_images && config.old_images=='Y'" class="w-70 rounded-3 text-right examItemImage greentestpicl  mx-auto mb-3 pe-1" 
                        :style="examItem.image_url.indexOf('background-image') > -1 ? 
                                    examItem.image_url : (examItem.image_url != '' ? 'background-image: url(data:image/jpeg;base64,'+examItem.image_url+');' : 
                                          'background-image: url(assets/img/noback.png);')" 
                        @data-url="examItem.eiid"
                        @click="OnItemClick(examItem.eiid ,examItem.eid , examItem.etid)" 
                        :id="'text'+examItem.eiid" >
                      <img src="assets/img/white_yes.png" class="avatar avatar-check rounded-0 hide" :id="'ok'+examItem.eiid"> 
                      <img src="assets/img/white_no.png" class="avatar avatar-check rounded-0 hide" :id="'fail'+examItem.eiid">
                    </div>
                    <div v-if="config && config.old_images && config.old_images=='N'" class="w-70 rounded-3 text-right examItemImage greentestpicl  mx-auto mb-3 pe-1" 
                        :style="examItem.image_url.indexOf('background-image') > -1 ? 
                                    examItem.image_url : (examItem.image_url != '' ? 'background-image: url('+examItem.image_url+');' : 
                                          'background-image: url(assets/img/noback.png);')" 
                        @data-url="examItem.eiid"
                        @click="OnItemClick(examItem.eiid ,examItem.eid , examItem.etid)" 
                        :id="'text'+examItem.eiid" >
                      <img src="assets/img/white_yes.png" class="avatar avatar-check rounded-0 hide" :id="'ok'+examItem.eiid"> 
                      <img src="assets/img/white_no.png" class="avatar avatar-check rounded-0 hide" :id="'fail'+examItem.eiid">
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
          <div v-else>
            <div class="px-3">  
              <div class="text-center choose_mt" >
                  <div class="position-relative chooise_mb" v-for="(examItem, examItemIndex) in item.item" :key='examItem.eiid'>
                    <div class="badge badge-secondary rounded-3 choose-btn text-simbold chooise text-wrap text-left" 
                        @click="OnItemClick(examItem.eiid ,examItem.eid , item.exam.etid)" 
                        :id="'text'+examItem.eiid">
                      <div class="d-flex"> 
                        <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="examItemIndex==0">A. </span> 
                        <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="examItemIndex==1">B. </span> 
                        <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="examItemIndex==2">C. </span> 
                        <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="examItemIndex==3">D. </span> 
                        <!-- eslint-disable vue/no-v-html --> 
                        <div class="text-mm text-simbold lh18 contentbox" v-html="examItem.name"></div>
                        <!--eslint-enable-->
                      </div> 
                    </div>
                    <img src="assets/img/img008.png" class="avatar avatar-check rounded-0 position-absolute topdowncenter ms-2 hide" :id="'ok'+examItem.eiid">
                    <img src="assets/img/ic_test_fail.png" class="avatar avatar-check rounded-0 position-absolute topdowncenter ms-2 hide" :id="'fail'+examItem.eiid">
                  </div> 
              </div>
            </div>
          </div>
          <!-- Exam Concept -->
          <div class="mt-3" v-show="isShowConcept">
            <h5 class="text-dark text-bold  mt-2 mb-3 text-mm">題目解析</h5>
            <div class="d-flex mb-2"> 
              <!-- eslint-disable vue/no-v-html -->
              <div class="text-s text-dark" v-html="item.concept != undefined && item.concept.length > 0 && item.concept[0].name.length > 0? item.concept[0].name : ''"></div>
              <!--eslint-enable-->
            </div> 
          </div>
          <div class="nicolespace"></div>
        </div>
      </div>


      <!--下一題-->
      <div class="text-center  my-5">
        <button type="button" class="btn btn-lg  btn-success rounded-pill w-85  text-s" @click="OnNextClick()" data-bs-dismiss="modal" :class="isCanNext ? '' : 'disabled'" >{{btnNextName}}</button>
      </div>

    </div>

    <div class="nicolespace"></div>  

    <!-- Modal 離開彈窗 -->
    <div class="modal fade " id="notice3" tabindex="-1" role="dialog" aria-labelledby="notice3Label" aria-hidden="true">
      <div class="modal-sm modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content nicole-modal"> 
          <div class="modal-header">
            <h4 class="text-center text-lg fw-bold text-dark mb-0">確定要離開嗎？</h4>
          </div>    
          <div class="modal-body">    
            <div class="text-center">
              <h5 class="text-simbold text-s text-dark mb-0">中途離開不會計入學習歷程喔</h5>
            </div>    
          </div>
          <div class="border-top d-flex align-content-center justify-content-between">
            <div class="w-50 text-center border-end py-3">
              <h5 class="text-success text-simbold mb-0" data-bs-dismiss="modal" @click="back()">離開</h5>
            </div>
            <div class="w-50 text-center py-3">
              <h5 class="text-success text-simbold mb-0 " data-bs-dismiss="modal">繼續答題</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 播放器 -->
    <video-player  class="video-player-box"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"

                 @play="onPlayerPlay($event)"
                 @pause="onPlayerPause($event)"
                 @ended="onPlayerEnded($event)"
                 @waiting="onPlayerWaiting($event)"
                 @playing="onPlayerPlaying($event)"
                 @loadeddata="onPlayerLoadeddata($event)"
                 @timeupdate="onPlayerTimeupdate($event)"
                 @canplay="onPlayerCanplay($event)"
                 @canplaythrough="onPlayerCanplaythrough($event)"
                 @statechanged="playerStateChanged($event)"
                 @ready="playerReadied"
                 v-show="false"
                 >
    </video-player>


  </body>
</div>
</template>


<script>
import Header from '@/components/Header.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import { config } from "@/include/config"; 

export default {
    name: 'ListeningExamStart',
    components: {
        Header,
    },
    data () {
      return { 
        // API Setting
          setDebugMode:false ,
          apiDebug: false,
          apiPostDebug: false,
          debugMode: false,
          apiName: "",
          form: {},


        // Data
          examGroupName: "",  // 題目文字
          examGroupAudio: "", // 音檔URL
          examGroupImage: "", // 圖片URL
          examParent: [],     // 選項
          eid: "", // 當前 Exam ID
          elid: "",
          egid: "",
          eplid: "",
          huepid: "",

          exam: [], // 所有資料
          currExam: [], // 當前題組的資料
          mEid: "", // 選項的判斷參數
          currIndex: -1,
          
          mExamLength: 0, // 題目數量
          countExam: 0,

          examPaperType: "0", // 考卷類型
          examPaperName: "",
          examPaperDescription: "", // 考卷描述

          timer: null, // 倒計時碼表
          time: 0, // 倒計時秒數

          config:"",
          query: "",

          examItemStyle: "", // Vue 題目類型 / text, image


          btnNextName: "進入測驗", // 下一題的按鈕名稱

        // 考卷類型
          examPaperTypeWordReexc: "1",
          examPaperTypeQuiz: "2",
          examPaperTypeListening: "3",
          examPaperTypeLevel: "4",

        // exam_type：單選、複選
          SingleExamItem: 1,
          MultipleExamItem: 2,

        // Status
          debug: true,

          isCheckAnswer: false, // 是否可重複點選選項

          isShowClock: false, // 是否顯示倒計時
          isSelect: false, // 是否被選取
          
          isShowConcept: true, // 是否顯示解析
          isExampleExam: true, // 是否為範例題目
          isShowAnswer: true, // 是否顯示答案

          recordExamItem: null, // 紀錄作答
          recordEiidArray: null, // 紀錄要送出API的作答
          isCanNext: false,

        // 已被選擇的 Exam Item ID
          selectExamItem: [], // Exam Item 的答對與答錯變化

        // 音檔
          isPlayAudio: false, // 是否正再播放音檔

          masterAudioPic: false,


          isAutoPlayAduio: false, // 是否要自動播放音檔
          audioUrlM3u8: "", // 音檔路徑
          lastDom: null,
          isGetAudioToken: false,


          playerOptions: {
            // videojs options
            muted: false,
            language: 'en',
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [],
            poster: "/static/images/author.jpg",
          },

        // 查閱歷史作答紀錄
        user_reply: [],
        isShowHistory: false,

        // 自動播放子題音檔
        currentAudio: -1, // G3 子題有音檔，需要自動播放
        examAudioM3u8: [],
      }
    },
    methods: {
      openLogin(){
        this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl:"ListeningExam", type:""}), this.openLoginCallback);
        this.backUrl = "";
      },
      openLoginCallback(data){},
      setOnBack(){
        this.$JsBridge.callHandler('setOnback', '', this.onBack);
      },
      onBack(){
        this.showModal('#notice3');
      },
      back() {
        if (this.$route.params.back != undefined && this.$route.params.back != "" ) {
          this.$router.push({
            name: this.$route.params.back
          });
        } else if (this.$route.query.back != undefined && this.$route.query.back != "" ) {
          this.$router.push({
            name: this.$route.query.back,
            query: this.$route.query
          });
        } else {
          this.$router.push({
            name: "ListeningExam",
            params: this.$route.query
          });
        }
      },
      
      getAPI(){
        this.apiName="page_listening_exam_start";
        oAC.getAllRecord(oAC.getApiParam(this), {uid:this.$GlobalData.uid, eplid: this.eplid, lsid: this.lsid, huepid: this.huepid, egid: this.egid} ).then(res => {
          if(res && res.get_page_listening_exam_start && res.get_page_listening_exam_start.user_reply != undefined && res.get_page_listening_exam_start.user_reply.length > 0){  
            this.user_reply = res.get_page_listening_exam_start.user_reply;
          }
          if(res && res.get_page_listening_exam_start && res.get_page_listening_exam_start.record != undefined && res.get_page_listening_exam_start.record.length > 0){  
            this.exam = res.get_page_listening_exam_start.record;
            this.constructorExam();
            this.InitExam();
            this.RandomExam();
            this.UpdateExam();
          }

          this.$nextTick(() => {
            // 查看歷史題目
            if (this.isShowHistory) {
              if (this.user_reply != undefined && this.user_reply.length > 0) {
                for (var i = this.user_reply.length - 1; i >= 0; i--) {
                  this.OnItemClick(parseInt(this.user_reply[i].result), this.user_reply[i].eid, "");
                }
              }
              this.OnNextClick();
            }
          }); 



          if (res && res.get_page_listening_exam_start != undefined && res.get_page_listening_exam_start.text != undefined) {
            if (res.get_page_listening_exam_start.text.name != undefined) { this.examPaperName = res.get_page_listening_exam_start.text.name; }
            if (res.get_page_listening_exam_start.text.description != undefined) { this.examPaperDescription = res.get_page_listening_exam_start.text.description ;}
          }

        }).catch((err)=> {
            oltConsole.log(err);
        });
      },

      // 整理資料
      constructorExam(){
        this.currIndex = -1;

        // 紀錄答案
        this.recordExamItem = new Map();
        this.recordEiidArray = new Map();

        if (this.exam != undefined && this.exam.length > 0) {
          this.examPaperType = this.exam[0].eptid;
          this.eplid = this.exam[0].eplid;
          this.huepid = this.exam[0].huepid;
        }
      },

      InitExam(){
        this.JumpToTop();
        
        this.isCheckAnswer = false;

        this.isShowConcept = false;

        this.isShowAnswer = false;

        this.isCanNext = false;

        this.isGetAudioToken = false;

        // console.log("this.isPlayAudio: " + this.isPlayAudio);
        // this.isPlayAudio = false;

        this.masterAudioPic = false;

        if (this.isExampleExam) {
          this.btnNextName = "進入測驗"; 
          this.isCanNext = true;
        } else if (this.examPaperType == this.examPaperTypeQuiz || this.examPaperType == this.examPaperTypeListening){
          this.btnNextName = "確認送出"; 
        } else {
          this.btnNextName = "下一題"; 
        } 

        if (!this.isExampleExam) {
          // 假如不是範例題，要清除考卷說明
          this.examPaperDescription = "";
        }


        this.recordExamItem.clear();

        this.recordEiidArray.clear();
      },
      // 隨機出題
      RandomExam() {
        if (this.exam != undefined && this.exam.length > 0) {
          // 用戶要觀看他曾經做過的題目
          if (this.egid != "") {
            this.isShowHistory = true;
            this.isExampleExam = false;
            for (var i = this.exam.length - 1; i >= 0; i--) {
              if ( this.egid == this.exam[i].exam_group.egid){
                this.currExam = this.exam[i];
              }
            }
            this.exam = [];
          }

          // 假如是聽力測驗，第一題要使用範例題目
          // 選項無法被點擊
          // 自動播放音檔，播放完畢正確答案自動出現
          // 過程中可以直接進入測驗
          else if (this.examPaperType == this.examPaperTypeListening && this.isExampleExam) {
            this.currExam = this.exam[0].example_item[0];
          } 
          else {
            var index = Math.floor(Math.random() * Math.floor(this.exam.length));
            if (index < this.exam.length) {
              this.currExam = this.exam[index];
              this.currIndex = index;
            }
          }
        }
      },
      // 更新題目
      UpdateExam() {
        this.examAudioM3u8 = [];
        this.currentAudio = -1;

        if (this.currExam != undefined) {
          if (this.currExam.exam_list != undefined && this.currExam.exam_list.length > 0) {
            this.mExamLength = this.currExam.exam_list.length;
          } 
          
          // 假如設定題數只有一題, 吃 exam 的 esid
          if (this.currExam.exam_mode == 1) {
              this.currExam.exam_style = (this.currExam.exam_list != undefined && this.currExam.exam_list.length > 0 && this.currExam.exam_list[0].exam != undefined && this.currExam.exam_list[0].exam.esid != undefined) ? this.currExam.exam_list[0].exam.esid : '';
          } 
          // 反之, 吃 exam_group 的 esid
          else {
              this.currExam.exam_style = (this.currExam.exam_group != undefined && this.currExam.exam_group.esid != undefined) ? this.currExam.exam_group.esid : '';
          }

          // Vue Exam Item 狀態清單
          this.selectExamItem = [];
          if (this.currExam != undefined){
            if (this.currExam.exam_list != undefined && this.currExam.exam_list.length > 0) {
              for (var i = 0; i < this.currExam.exam_list.length; i++) {
                this.selectExamItem[i] = [];
                for (var j = 0; j < this.currExam.exam_list[i].item.length; j++) {
                  let temp = {
                    id: this.currExam.exam_list[i].item[j].eiid,
                    ok: false,
                    fail: false,
                    isCheck: false,
                  };
                  this.selectExamItem[i][j] = temp;
                }          
              }            
            }
          }

          this.isAutoPlayAduio = true;

          this.examItemStyle = "text";
          switch (this.currExam.exam_style) {
              case "G1":
                  this.G1(this.currExam);
                  break;
              case "G2":
                  this.G2(this.currExam);
                  break;
              case "G3":
                  this.G3(this.currExam);
                  break;
              case "G4":
                  this.G4(this.currExam);
                  break;
              case "G5":
                  this.G5(this.currExam);
                  break;
              case "P1":
                  this.P1(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "P2":
                  this.P2(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "P3":
                  this.P3(this.currExam);
                  this.isAutoPlayAduio = false;
                  this.mExamLength = 1;
                  break;
              case "P4":
                  this.P4(this.currExam);
                  this.mExamLength = 1;
                  this.examItemStyle = "image";
                  break;
              case "P5":
                  this.P5(this.currExam);
                  this.isAutoPlayAduio = false;
                  this.mExamLength = 1;
                  this.examItemStyle = "image";
                  break;
              case "P6":
                  this.P6(this.currExam);
                  this.isAutoPlayAduio = false;
                  this.mExamLength = 1;
                  this.examItemStyle = "image";
                  break;
              case "Q1":
                  this.Q1(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q2":
                  this.Q2(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q3":
                  this.Q3(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q4":
                  this.Q4(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q5":
                  this.Q5(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q6":
                  this.Q6(this.currExam);
                  this.mExamLength = 1;
                  break;
          }
          this.isAutoPlay = false;
        }

        // 範例題目
        if (this.isExampleExam) {
          this.playExampleAudio();
        }
      },

      // 按鍵監聽 - 選項
      OnItemClick(eiid, eid, exam_type) {
        if (this.isCheckAnswer || this.isExampleExam) {
          return false;
        }

        let isSelect = 'Y';

        // 範例題不能被點選
        if (this.currExam.exam_group.is_sample != undefined && this.currExam.exam_group.is_sample == 'Y') {
          return;
        }
        
        // 選取特效 - 單選
        if (exam_type == this.SingleExamItem) {
          if (this.recordExamItem.size > 0) {
            // 假如點選同一個選項，必須取消選取
            if (this.recordExamItem.has(eiid)) {
              this.recordExamItem.delete(eiid);
              this.changeExamItemStatus(eiid, "delete");
              isSelect = 'N';
            }
            // 假如點選不同的選項，必須先判斷是否為同一小題
            // 1. 同一小題, 要取消同一小題的其他選項
            // 2. 不同小題, 不用理會直接變更為選取狀態
            else {
              this.recordExamItem.forEach((value_eid, key_eiid) => {
                if (value_eid == eid) {
                  this.recordExamItem.delete(key_eiid);
                  this.changeExamItemStatus(key_eiid, "delete");
                }
              });
              this.recordExamItem.set(eiid, eid);
              this.changeExamItemStatus(eiid, "added");
              isSelect = 'Y';
            }
          } else {
            this.recordExamItem.set(eiid, eid);
            this.changeExamItemStatus(eiid, "added");
            isSelect = 'Y';
          }
        } 
        // 選取特效 - 複選
        else if (exam_type == this.MultipleExamItem) {
            if (this.recordExamItem.has(eiid)) {
                this.recordExamItem.delete(eiid);
                this.changeExamItemStatus(eiid, "delete");
            } else {
                this.recordExamItem.set(eiid, eid);
                this.changeExamItemStatus(eiid, "added");
            }
            // 確認暫存答案陣列中是否還有當前題目的作答紀錄
            // 假如有的話就要將 isSelect設置成為 Y, 反之則設置成為 N 
            isSelect = 'N';
            this.recordExamItem.forEach(function(mEid, eiid) {
                if (mEid == eid) {
                    isSelect = 'Y';
                }
            })
        } 
        else {
          this.recordExamItem.set(eiid, eid);
          this.changeExamItemStatus(eiid, "added");
          isSelect = 'Y';
        }


        // 圖片式選項
        if (this.currExam.exam_style == "P4" || this.currExam.exam_style == "P5" || this.currExam.exam_style == "P6") {
          // 考卷為等級測驗 - 下一步的行為 - 直接顯示答案
          if (this.examPaperType == this.examPaperTypeLevel) {
            this.levelExamNext("image", eiid, eid);
          } 
          // 考卷為其他 - 下一步的行為 - 紀錄是否作答完所有題目，是否可點選送出
          else {
            this.otherExamNext(eid, isSelect);
          }
        }
        // 文字式選項
        else {
          // 考卷為等級測驗 - 下一步的行為 - 直接顯示答案
          if (this.examPaperType == this.examPaperTypeLevel) {
            this.levelExamNext("text", eiid, eid);
          } 
          // 考卷為其他 - 下一步的行為 - 紀錄是否作答完所有題目，是否可點選送出
          else {
            this.otherExamNext(eid, isSelect);
          }
        }
      },

      // 題目各種題型
        /** 多個題目, emid = 2
         * Exam Group - 音檔、文字
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - X
         */
        G1(examList) {
            if (examList == undefined) return;

            // Exam Group 文字    
            this.examGroupName = examList.exam_group.name;

            // Exam Group 圖片
            this.examGroupImage = "";

            // Exam Group 音檔
            this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
            this.startPlayVoice(examList.exam_group.audio_url_m3u8, "");

            // 取得總題數
            this.mExamLength = examList.exam_list.length;
        },

        /** 多個題目, emid = 2
         * Exam Group - 音檔、音檔文字
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - X
         */
        G2(examList) {
          if (examList == undefined) return;

          // Exam Group 文字    
          this.examGroupName = examList.exam_group.name;

          // Exam Group 圖片
          this.examGroupImage = "";

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
          this.startPlayVoice(examList.exam_group.audio_url_m3u8, "");

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
          if (examList.exam_list.length > 0) {
            for (var i = 0; i < examList.exam_list.length; i++) {
              if (examList.exam_list[i].exam.esid == "Q3" && examList.exam_list[i].exam.audio_url_m3u8.length > 0) {
                this.examAudioM3u8[i] = examList.exam_list[i].exam.audio_url_m3u8;
              }
            }
          }
        },

        /**
         * Exam Group - 音檔
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - X
         */
        G3(examList) {
          if (examList == undefined) return;

          // Exam Group 文字    
          this.examGroupName = "";

          // Exam Group 圖片
          this.examGroupImage = "";

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
          this.startPlayVoice(examList.exam_group.audio_url_m3u8, "");

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
          if (examList.exam_list.length > 0) {
            for (var i = 0; i < examList.exam_list.length; i++) {
              if (examList.exam_list[i].exam.esid == "Q3" && examList.exam_list[i].exam.audio_url_m3u8.length > 0) {
                this.examAudioM3u8[i] = examList.exam_list[i].exam.audio_url_m3u8;
              }
            }
          }
        },

        /** 圖片、音檔*/
        G4(examList) {
          if (examList == undefined) return;

          // Exam Group 文字    
          this.examGroupName = "";

          // Exam Group 圖片
          this.examGroupImage = examList.exam_group.egid_image_url;

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
          this.startPlayVoice(examList.exam_group.audio_url_m3u8, "");

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
          if (examList.exam_list.length > 0) {
            for (var i = 0; i < examList.exam_list.length; i++) {
              if (examList.exam_list[i].exam.esid == "Q3" && examList.exam_list[i].exam.audio_url_m3u8.length > 0) {
                this.examAudioM3u8[i] = examList.exam_list[i].exam.audio_url_m3u8;
              }
            }
          }
        },

        /** 圖片、音檔、文字 */
        G5(examList) {
          if (examList == undefined) return;
          
          // Exam Group 文字    
          this.examGroupName = examList.exam_group.name;

          // Exam Group 圖片
          this.examGroupImage = examList.exam_group.egid_image_url;

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
          this.startPlayVoice(examList.exam_group.audio_url_m3u8, "");

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
        },

        /** 單個題目, emid = 1
         * Exam Group - 圖片、音檔、文字
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - O
         */
        P1(examList) {
          if (examList == undefined) return;
          
          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = examList.exam_list[0].exam['name'];

            // Exam Group 圖片
            this.examGroupImage = examList.exam_list[0].exam['image_url'];

            // Exam Group 音檔
            this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
            this.startPlayVoice(examList.exam_list[0].exam['audio_url_m3u8'], "");
          }
        },

        /**
         * Exam Group - 圖片、音檔
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - O
         */
        P2(examList) {
          if (examList == undefined) return;
          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = "";

            // Exam Group 圖片
            this.examGroupImage = examList.exam_list[0].exam['image_url'];

            // Exam Group 音檔
            this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
            this.startPlayVoice(examList.exam_list[0].exam['audio_url_m3u8'], "");
          }
        },

        /**
         * Exam Group - 圖片、文字
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - O
         */
        P3(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = examList.exam_list[0].exam['name'];

            // Exam Group 圖片
            this.examGroupImage = examList.exam_list[0].exam['image_url'];

            // Exam Group 音檔
            this.audioUrlM3u8 = "";
          }
        },

        /**
         * Exam Group - 音檔
         * Exam - X
         * Exam Item - 圖片
         * Exam Concept - O
         */
        P4(examList) { 
          if (examList == undefined) return;
          if (examList.exam_list.length > 0) {


            // Exam Group 文字    
            this.examGroupName = '';

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
            this.startPlayVoice(examList.exam_list[0].exam['audio_url_m3u8'], "");
          }
        },

        /**
         * Exam Group - 文字
         * Exam - 文字
         * Exam Item - 圖片
         * Exam Concept - O
         */
        P5(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = examList.exam_list[0].exam['name'];

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            this.audioUrlM3u8 = '';
          }
        },

        /**
         * Exam Group - 文字
         * Exam - 文字
         * Exam Item - 圖片
         * Exam Concept - O
         */
        P6(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {


            // Exam Group 文字    
            this.examGroupName = examList.exam_list[0].exam['name'];

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
            this.startPlayVoice(examList.exam_list[0].exam['audio_url_m3u8'], "");
          }
        },

        /**
         * Exam Group - 圖片、文字
         * Exam - 文字
         * Exam Item - 文字
         * Exam Concept - X
         */
        Q1(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = examList.exam_list[0].exam['name'];

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
            this.startPlayVoice(examList.exam_list[0].exam['audio_url_m3u8'], "");
          }
        },

        /**
         * Exam Group - 音檔、文字
         * Exam - 無
         * Exam Item - 文字
         * Exam Concept - X
         */
        Q2(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = examList.exam_list[0].exam['name'];

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
            this.startPlayVoice(examList.exam_list[0].exam['audio_url_m3u8'], "");
          }
        },

        /**
         * Exam Group - 音檔、文字
         * Exam - 無
         * Exam Item - 文字
         * Exam Concept - O
         */
        Q3(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = examList.exam_list[0].exam['name'];

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
            this.startPlayVoice(examList.exam_list[0].exam['audio_url_m3u8'], "");
          }
        },

        /**
         * Exam Group - 音檔
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - O
         */
        Q4(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = '';

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
            this.startPlayVoice(examList.exam_list[0].exam['audio_url_m3u8'], "");
          }
        },

        /**
         * Exam Group - X
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - O
         */
        Q5(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {

            // Exam Group 文字    
            this.examGroupName = '';

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            this.audioUrlM3u8 = '';
          }
        },

        /**
         * Exam Group - 文字(填空))
         * Exam - X
         * Exam Item - 文字
         * Exam Concept - O
         */
        Q6(examList) {
          if (examList == undefined) return;

          // Exam Group 文字    
          this.examGroupName = examList.exam_list[0].exam['name'];

          // Exam Group 圖片
          this.examGroupImage = '';

          // Exam Group 音檔
          this.audioUrlM3u8 = '';
        },

      // 選項
        IDontKnowTimer(){
          var tempExam = this.exam;
          var initExam = this.InitExam;
          var randomExam = this.RandomExam;
          var updateExam = this.UpdateExam;
          var openComplete = this.openComplete;

          this.exam.splice(this.currIndex, 1);

          this.form["exam"] = {
            "egid": this.currExam.exam_group.egid,
            "eid": this.currExam.exam_list[0].exam.eid,
            "result_type": "id",
            "eiid": undefined
          };
          
          this.postExamAnswer(function(res){
            setTimeout(function() {
              if (tempExam != undefined && tempExam.length > 0) {
                  initExam();
                  randomExam();
                  updateExam();
              } else {
                openComplete();
              }
            }, 500);
          });
        },
      // 播放音檔
        loadCorrectAudio(){
          this.$JsBridge.callHandler('loadCorrectAudioVue',JSON.stringify({backUrl:"ListeningExamStart", type:""}), this.getExamAudioCallback);
        },

        loadErrorAudio(){
          this.$JsBridge.callHandler('loadErrorAudioVue',JSON.stringify({backUrl:"ListeningExamStart", type:""}), this.getExamAudioCallback);
        },

        stopPlayVoice(){
          this.$refs.videoPlayer.player.pause();
          /**
           * 播放中
           * 按下暫停 ，狀態改成 N，播放器 pause()
           * 播放器設置 進度為 0
           * 播放器 canPlay 觸發
           * 因為我聽力測驗要自動播放音檔，所以我沒有檔他
           * 假如進入 canPlay 那個 callback 就會自動播放
           */
          if (this.lastDom != null && this.lastDom != "") {
            this.lastDom.src = "assets/img/play_nostar.png";
            this.lastDom = null;
          }
          

        },
        startPlayVoice(m3u8, dom) {
          if (dom == undefined || dom == ""){
            this.masterAudioPic = true;
            this.apiName = "video_get_token";
            oAC.getAllRecord(oAC.getApiParam(this), {src: m3u8}).then(res =>{
              if (res.get_video_token != undefined && res.get_video_token.data != undefined && res.get_video_token.data.token != undefined) {
                var json = {
                    type: "application/x-mpegURL",
                    src : `${m3u8}?token=${res.get_video_token.data.token}`,
                };
                this.playerOptions.sources = [];
                this.playerOptions.sources.push(json);
                this.isGetAudioToken = true;

                if(this.$JsBridge.isiOS){
                  this.$refs.videoPlayer.muted = true;
                }
              }
            }).catch((err)=> {
              oltConsole.log(err);
            });
          }
          else if (this.lastDom == dom ) {
            this.stopPlayVoice();
          } 
          else {

            // 手動點選喇把，就不要再自動播放了
            this.examAudioM3u8 = [];
            this.masterAudioPic = false;


            this.apiName = "video_get_token";
            oAC.getAllRecord(oAC.getApiParam(this), {src: m3u8}).then(res =>{
              if (res.get_video_token != undefined && res.get_video_token.data != undefined && res.get_video_token.data.token != undefined) {
                var json = {
                    type: "application/x-mpegURL",
                    src : `${m3u8}?token=${res.get_video_token.data.token}`,
                };
                this.playerOptions.sources = [];
                this.playerOptions.sources.push(json);
                this.isGetAudioToken = true;
                if (this.lastDom != null && this.lastDom != "" && this.lastDom != dom) {
                  this.lastDom.src = "assets/img/play_nostar.png";
                }
                this.lastDom = dom;
                if(this.$JsBridge.isiOS){
                  this.$refs.videoPlayer.muted = true;
                }
              }
            }).catch((err)=> {
              oltConsole.log(err);
            });
          }
        },
        /**名稱: 答題音效
         * 描述: 答對或答錯要播放音效 , 在進入下一題
         * 用於: 等級測驗
         */
        getExamAudioCallback() {
          this.$JsBridge.callHandler('resetMediaPlayerVue',JSON.stringify({backUrl:"ListeningExamStart", type:""}), this.resetMediaPlayerVueCallback);
          if (this.examPaperType == this.examPaperTypeLevel) {
            if (this.exam != undefined && this.exam.length > 0) {
              this.InitExam();
              this.RandomExam();
              this.UpdateExam();
            } else {
              this.openComplete();
            }
          }
        },
        playExampleAudio(){
          if (this.isPlayAudio) {
            this.stopPlayVoice();
          } else if (this.isGetAudioToken) {
            this.$refs.videoPlayer.player.play();
            this.isPlayAudio = true;
          } else {
            this.apiName = "video_get_token";
            oAC.getAllRecord(oAC.getApiParam(this), {src: this.audioUrlM3u8}).then(res =>{
              if (res.get_video_token != undefined && res.get_video_token.data != undefined && res.get_video_token.data.token != undefined) {
                var json = {
                    type: "application/x-mpegURL",
                    src : `${this.audioUrlM3u8}?token=${res.get_video_token.data.token}`,
                };
                this.playerOptions.sources = [];
                this.playerOptions.sources.push(json);
                this.isGetAudioToken = true;
                if(this.$JsBridge.isiOS){
                  this.$refs.videoPlayer.muted = true;
                }

                this.masterAudioPic = true;

              }
            }).catch((err)=> {
              oltConsole.log(err);
            });              
          }
        },
        resetMediaPlayerVueCallback(){
          this.$refs.videoPlayer.player.pause();
        },

      // 將作答紀錄儲存在 DB
      postExamAnswer(callback) {
        this.apiName = "page_listening_exam_start_answer";
        oAC.getAllRecord(oAC.getApiParam(this), this.form ).then(callback).catch((err)=> {
            oltConsole.log(err);
        });
      },

      postExamAnswer2(data) {
        this.form = {
          eplid: this.eplid,
          huepid: this.huepid,
          uid: this.$GlobalData.uid,
          exam: data
        };

        this.apiName="page_listening_exam_start_answer";
        oAC.postRecord(oAC.getApiParam(this)).then((res)=> {
          
        }).catch((err)=> {
          oltConsole.log(err);
        });
      },

      openComplete(){
        this.$router.push({
          name: "ListeningExamComplete",
          params:{
            elid: this.elid,
            huepid: this.huepid,
            eplid: this.eplid,
            uid: this.$GlobalData.uid,
            back: "ListeningExam"
          }
        });
      },

      OnNextClick(){
        this.stopPlayVoice();
        // 範例題目可以直接進入下一題
        if (this.isExampleExam) {
          this.isExampleExam = false;
          if (this.exam != undefined && this.exam.length > 0) {
            this.InitExam();
            this.RandomExam();
            this.UpdateExam();
          } else {
            this.openComplete();
          }
        } 
        // 其他題目要顯示答案和解析，再按一次才是進入下一題
        else {
          // 還沒顯示答案要顯示答案
          if (!this.isShowAnswer) {

            this.showVueAnswer();

            this.isCheckAnswer = true;

            if (this.exam != undefined && this.exam.length > 0) {
              this.btnNextName = "下一題";
            } else {
              this.btnNextName = "完成測驗";
            }

          } 
          // 再按一次才是進入下一題
          else {
            this.audioUrlM3u8 = "";
            this.isCheckAnswer = false;

            if (this.exam != undefined && this.exam.length > 0) {
              this.InitExam();
              this.RandomExam();
              this.UpdateExam();
            } else {
              this.openComplete();
            }
          }
        }
      },

      changeExampleExamItemStatus(){
        let tempArr = this.currExam;
        if (this.currExam.exam_group.emid == "1") {
          if (this.currExam != undefined) {
            if (this.currExam.exam_list != undefined && this.currExam.exam_list.length > 0) {
              if (this.currExam.exam_list[0].answer != undefined && this.currExam.exam_list[0].answer.length > 0) {
                for (var i = this.currExam.exam_list[0].answer.length - 1; i >= 0; i--) {
                  $("#ok"+this.currExam.exam_list[0].answer[i].eiid).removeClass("hide");
                  // 假如是圖片選項
                  if (this.currExam.exam_style == "P4" || this.currExam.exam_style == "P5" || this.currExam.exam_style == "P6") {
                    $("#text"+this.currExam.exam_list[0].answer[i].eiid).addClass("greentestpicborder");

                    // 綠色漸層
                    let tempImage = $("#text"+this.currExam.exam_list[0].answer[i].eiid).css("background-image");
                    let imageSuccess = 'background-image: linear-gradient(to bottom, rgba(96, 255, 231, 0.4), rgba(96, 255, 231, 0.4)), '+tempImage; // Success

                    for (var k = 0; k < tempArr.exam_list.length; k++) {
                      for (var l = 0; l < tempArr.exam_list[k].item.length; l++) {
                        if (tempArr.exam_list[k].item[l].eiid == this.currExam.exam_list[0].answer[i].eiid) {
                          tempArr.exam_list[k].item[l].image_url = imageSuccess;
                        }
                      }
                    }
                  } 
                  // 假如是文字選項
                  else {
                    $("#text"+this.currExam.exam_list[0].answer[i].eiid).removeClass("badge-secondary");
                    $("#text"+this.currExam.exam_list[0].answer[i].eiid).addClass("badge-success");
                  }
                }
              }
            }
          }
        } else if (this.currExam.exam_group.emid == "2") {
          // 圖片選項
          if (this.currExam.exam_style == "P4" || this.currExam.exam_style == "P5" || this.currExam.exam_style == "P6") {
            // 多少個小題
            for (var i = 0; i < this.currExam.exam_list.length; i++) {
              if (this.currExam.exam_list[i].answer != undefined && this.currExam.exam_list[i].answer.length > 0) {
                // 多少個答案
                for (var j = 0; j < this.currExam.exam_list[i].answer.length; j++) {
                  // 加上正確的圖示
                  if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                    $("#ok"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("hide");
                    // 綠色漸層
                    let tempImage = $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).css("background-image");
                    let imageSuccess = 'background-image: linear-gradient(to bottom, rgba(96, 255, 231, 0.4), rgba(96, 255, 231, 0.4)), '+tempImage; // Success

                    for (var k = 0; k < tempArr.exam_list.length; k++) {
                      for (var l = 0; l < tempArr.exam_list[k].item.length; l++) {
                        if (tempArr.exam_list[k].item[l].eiid == this.currExam.exam_list[i].answer[j]["eiid"]) {
                          tempArr.exam_list[k].item[l].image_url = imageSuccess;
                        }
                      }
                    }
                    // 綠色邊框
                    $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).addClass("greentestpicborder");
                  }

                }
              }
            }
          }
          // 文字選項
          else {
            // 多少個小題
            for (var i = 0; i < this.currExam.exam_list.length; i++) {
              if (this.currExam.exam_list[i].answer != undefined && this.currExam.exam_list[i].answer.length > 0) {
                // 多少個答案
                for (var j = 0; j < this.currExam.exam_list[i].answer.length; j++) {
                  // 加上正確的圖示
                  // if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                    $("#ok"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("hide");
                    $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("greentestpicborder");
                    $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("badge-secondary");
                    $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).addClass("badge-success");
                  // }
                }                      
              }
            }
          }
        }
      },
      changeExamItemStatus(eiid, type){
        if (this.selectExamItem != undefined && this.selectExamItem.length > 0) {
          for (var i = this.selectExamItem.length - 1; i >= 0; i--) {
            for (var j = this.selectExamItem[i].length - 1; j >= 0; j--) {
              if (this.selectExamItem[i][j].id == eiid){
                // 假如是圖片選項
                if (this.currExam.exam_style == "P4" || this.currExam.exam_style == "P5" || this.currExam.exam_style == "P6") {
                  if (type == "delete") {
                    $("#text"+this.selectExamItem[i][j].id).removeClass("greentestpicborder");
                  } else {
                    $("#text"+this.selectExamItem[i][j].id).addClass("greentestpicborder");
                  }


                } 
                // 假如是文字選項
                else {
                  if (type == "delete") {
                    $("#text"+this.selectExamItem[i][j].id).removeClass("greentestpicborder");
                  } else {
                    $("#text"+this.selectExamItem[i][j].id).addClass("greentestpicborder");
                  }
                }
              }
            }
          }
        }
      },
      showVueAnswer(){
        let playCorrectAudio = false;
        this.isShowAnswer = true;
        this.isShowConcept = true;
        let tempArr = this.currExam;
        let exam = this.currExam;

        // 圖片選項
        if (this.currExam.exam_style == "P4" || this.currExam.exam_style == "P5" || this.currExam.exam_style == "P6") {
          // 多少個小題
          for (var i = 0; i < this.currExam.exam_list.length; i++) {
            if (this.currExam.exam_list[i].answer != undefined && this.currExam.exam_list[i].answer.length > 0) {
              // 多少個答案
              for (var j = 0; j < this.currExam.exam_list[i].answer.length; j++) {
                // 加上正確的圖示
                if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                  $("#ok"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("hide");
                  // 綠色漸層
                  let tempImage = $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).css("background-image");
                  let imageSuccess = 'background-image: linear-gradient(to bottom, rgba(96, 255, 231, 0.4), rgba(96, 255, 231, 0.4)), '+tempImage; // Success

                  for (var k = 0; k < tempArr.exam_list.length; k++) {
                    for (var l = 0; l < tempArr.exam_list[k].item.length; l++) {
                      if (tempArr.exam_list[k].item[l].eiid == this.currExam.exam_list[i].answer[j]["eiid"]) {
                        tempArr.exam_list[k].item[l].image_url = imageSuccess;
                      }
                    }
                  }

                  // 綠色邊框
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).addClass("greentestpicborder");

                  playCorrectAudio = true;
                } 
                else {
                  // 綠色漸層
                  let tempImage = $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).css("background-image");
                  let imageSuccess = 'background-image: linear-gradient(to bottom, rgba(96, 255, 231, 0.4), rgba(96, 255, 231, 0.4)), '+tempImage; // Success

                  for (var k = 0; k < tempArr.exam_list.length; k++) {
                    for (var l = 0; l < tempArr.exam_list[k].item.length; l++) {
                      if (tempArr.exam_list[k].item[l].eiid == this.currExam.exam_list[i].answer[j]["eiid"]) {
                        tempArr.exam_list[k].item[l].image_url = imageSuccess;
                      }
                    }
                  }

                  // 綠色邊框
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).addClass("greentestpicborder");
                }

                // 紀錄答案, 並刪除目前紀錄在 MAP 裡面的資料                    
                if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                  this.recordEiidArray.set(this.currExam.exam_list[i].answer[j]["eiid"], this.currExam.exam_list[i].answer[j]["eid"]);
                  this.recordExamItem.delete(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]));
                }
              }
            }
          }
          // 假如 MAP 裡面還有剩餘資料， 必須將它標示為錯誤的
          if (this.recordExamItem.size > 0) {
            this.recordExamItem.forEach((value_eid, key_eiid, items) => {
              // 紀錄答案
              this.recordEiidArray.set(key_eiid, value_eid);
              $("#fail"+key_eiid).removeClass("hide");
              $("#text"+key_eiid).removeClass("greentestpicborder");
              // 紅色漸層
              let tempImage = $("#text"+key_eiid).css("background-image");
              let imageError = 'background-image: linear-gradient(to bottom, rgba(255, 158, 158, 0.4), rgba(255, 158, 158, 0.4)), '+tempImage; // Error

              for (var i = 0; i < tempArr.exam_list.length; i++) {
                for (var j = 0; j < tempArr.exam_list[i].item.length; j++) {
                  if (tempArr.exam_list[i].item[j].eiid == key_eiid) {
                    tempArr.exam_list[i].item[j].image_url = imageError;
                  }
                }
              }

              // 紅色邊框
              $("#text"+key_eiid).addClass("redtestpicborder");
              playCorrectAudio = false;
            });
          }
        }
        // 文字選項
        else {
          // 多少個小題
          for (var i = 0; i < this.currExam.exam_list.length; i++) {
            if (this.currExam.exam_list[i].answer != undefined && this.currExam.exam_list[i].answer.length > 0) {
              // 多少個答案
              for (var j = 0; j < this.currExam.exam_list[i].answer.length; j++) {
                // 加上正確的圖示
                if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                  $("#ok"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("hide");
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("greentestpicborder");
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("badge-secondary");
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).addClass("badge-success");
                  playCorrectAudio = true;
                } else {
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("greentestpicborder");
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("badge-secondary");
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).addClass("badge-success");
                }
                // 紀錄答案, 並刪除目前紀錄在 MAP 裡面的資料                    
                if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                  this.recordEiidArray.set(this.currExam.exam_list[i].answer[j]["eiid"], this.currExam.exam_list[i].answer[j]["eid"]);
                  this.recordExamItem.delete(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]));
                }

              }                      
            }
          }
          // 假如 MAP 裡面還有剩餘資料， 必須將它標示為錯誤的
          if (this.recordExamItem.size > 0) {
            this.recordExamItem.forEach((value_eid, key_eiid, items) => {
              // 紀錄答案
              this.recordEiidArray.set(key_eiid, value_eid);
                $("#fail"+key_eiid).removeClass("hide");
                $("#text"+key_eiid).removeClass("badge-secondary");
                $("#text"+key_eiid).removeClass("greentestpicborder");
                $("#text"+key_eiid).addClass("badge-danger");
                playCorrectAudio = false;
            });
          }
        }
        this.currExam = tempArr;

        if (!this.isShowHistory) {
          this.saveAnswer();
        }

        // 刪除出現的題目
        this.exam.splice(this.currIndex, 1);

        if (playCorrectAudio) {
          this.loadCorrectAudio();
        } else {
          this.loadErrorAudio();
        }
      },
      levelExamNext(type, eiid, eid){
        // 停止計時器
        // this.clearInterval(this.timer);

        // 停止播放音檔
        this.stopPlayVoice();

        let playCorrectAudio = false;
        let exam = this.currExam;


        if (type == "image") {
          // 選了我不知道的選項
          if (eiid == undefined) {
            this.IDontKnowTimer();
          }
          // 選擇其他選項
          else {
            // 多少個小題
            for (var i = 0; i < this.currExam.exam_list.length; i++) {
              if (this.currExam.exam_list[i].answer != undefined && this.currExam.exam_list[i].answer.length > 0) {
                // 多少個答案
                for (var j = 0; j < this.currExam.exam_list[i].answer.length; j++) {
                  // 加上正確的圖示
                  if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                    for (var k = 0; k < this.selectExamItem.length; k++) {
                      if (this.selectExamItem[k].id == this.currExam.exam_list[i].answer[j]["eiid"]) {
                        $("#ok"+this.selectExamItem[k].id).removeClass("hide");
                        $("#text"+this.selectExamItem[k].id).addClass("greentestpicborder");
                        playCorrectAudio = true;
                      }
                    }
                  }

                  // 紀錄答案, 並刪除目前紀錄在 MAP 裡面的資料                    
                  if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                    this.recordEiidArray.set(this.currExam.exam_list[i].answer[j]["eiid"], this.currExam.exam_list[i].answer[j]["eid"]);
                    this.recordExamItem.delete(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]));
                  }
                }
              }
            }

            // 假如 MAP 裡面還有剩餘資料， 必須將它標示為錯誤的
            if (this.recordExamItem.size > 0) {
              var tempArr = this.selectExamItem;
              var tempExamItem = this.currExam;
              this.recordExamItem.forEach((value_eid, key_eiid, items) => {
                // 紀錄答案
                this.recordEiidArray.set(key_eiid, value_eid);
                exam = this.currExam;
                if (tempArr.length > 0) {
                  for (var i = 0; i < tempArr.length; i++) {
                    if (tempArr[i].id == key_eiid) {
                      $("#fail"+key_eiid).removeClass("hide");
                      $("#text"+key_eiid).addClass("redtestpicborder");
                      playCorrectAudio = false;
                    }
                  }    
                }
              })
            }
          } 
        } 
        else if (type == "text") {
          // 選了我不知道的選項
          if (eiid == undefined) {
            this.IDontKnowTimer();
          }
          // 選擇其他選項
          else {
            // 多少個小題
            for (var i = 0; i < this.currExam.exam_list.length; i++) {
              if (this.currExam.exam_list[i].answer != undefined && this.currExam.exam_list[i].answer.length > 0) {
                // 多少個答案
                for (var j = 0; j < this.currExam.exam_list[i].answer.length; j++) {
                  // 加上正確的圖示
                  if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                    for (var k = 0; k < this.selectExamItem.length; k++) {
                      if (this.selectExamItem[k].id == this.currExam.exam_list[i].answer[j]["eiid"]) {
                        $("#ok"+this.selectExamItem[k].id).removeClass("hide");
                        $("#text"+this.selectExamItem[k].id).removeClass("badge-secondary");
                        $("#text"+this.selectExamItem[k].id).addClass("badge-success");
                      }
                    }
                    playCorrectAudio = true;
                  }

                  // 紀錄答案, 並刪除目前紀錄在 MAP 裡面的資料                    
                  if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                    this.recordEiidArray.set(this.currExam.exam_list[i].answer[j]["eiid"], this.currExam.exam_list[i].answer[j]["eid"]);
                    this.recordExamItem.delete(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]));
                  }
                }                      
              }
            }

            // 假如 MAP 裡面還有剩餘資料， 必須將它標示為錯誤的
            if (this.recordExamItem.size > 0) {
              var tempArr = this.selectExamItem;
              this.recordExamItem.forEach((value_eid, key_eiid, items) => {
                // 紀錄答案
                this.recordEiidArray.set(key_eiid, value_eid);
                if (tempArr.length > 0) {
                  for (var i = 0; i < tempArr.length; i++) {
                    if (tempArr[i].id == key_eiid) {
                      $("#fail"+key_eiid).removeClass("hide");
                      $("#text"+key_eiid).removeClass("badge-secondary");
                      $("#text"+key_eiid).addClass("badge-danger");
                      playCorrectAudio = false;
                    }
                  }    
                }
              });
            }
          }
        }

        this.examNextTwo(playCorrectAudio);
      },
      otherExamNext(eid, isSelect){
        let countExam = 0;
        // 顯示/隱藏下一題按鈕
        // 不能以數量來做為顯示的依據，因為題組的題目是複選題時，會超過
        // 正確做法是 -> 先確認每個題目是否有作答, 假如都有作答就給過 
        // mExamLength : 總題數
        $(".exam").each(function(a, b) {
            if (b.id == eid) {
                $("#" + b.id).attr("data", isSelect);
            }
            if ($("#" + b.id).attr("data") == 'Y') {
                countExam++;
            }
        });

        if (countExam == this.mExamLength) {
          this.isCanNext = true;
        } else {
          this.isCanNext = false;
        }
      },
      examNextTwo(playCorrectAudio){
        this.saveAnswer();

        // 刪除出現的題目
        this.exam.splice(this.currIndex, 1);
        if (playCorrectAudio) {
          this.loadCorrectAudio();
        } else {
          this.loadErrorAudio();
        }
      },
      saveAnswer(){
        // 儲存作答紀錄
        if (this.recordEiidArray.size > 0) {
          var tempExam = [];
          var postExamAnswer2 = this.postExamAnswer2;
          var tempExamArr = [];
          this.form["uid"] = this.$GlobalData.uid;
          this.recordEiidArray.forEach((value_eid, key_eiid, item) => {
            tempExam = {
              "egid": JSON.stringify(this.currExam.exam_group.egid),
              "eid": JSON.stringify(value_eid),
              "eiid": JSON.stringify(key_eiid),
              "result_type": "id"
            };
            tempExamArr.push(tempExam);
          });


          postExamAnswer2(tempExamArr);
        }
      },

      // listen event
      onPlayerPlay(player) {
          // console.log(`onPlayerPlay ==> ${player}`);
      },
      onPlayerPause(player) {
          // console.log(`onPlayerPause ==> ${player}`);
      },
      playerStateChanged(playerCurrentState) {
        // console.log(`playerStateChanged ==> ${playerCurrentState}`);
      },
      onPlayerEnded(player) {
        // console.log(`onPlayerEnded ==> ${player}`);
        this.isPlayAudio = false;

        if (this.masterAudioPic) {
          this.masterAudioPic = false;
        } else if (this.lastDom != null && this.lastDom != "") {
          this.lastDom.src = "assets/img/play_nostar.png";  
        }


        if (this.isExampleExam) {
          this.changeExampleExamItemStatus();
        }

        if (this.examAudioM3u8.length > 0) {
          this.currentAudio++;
          this.startPlayVoice(this.examAudioM3u8[0]);
          this.masterAudioPic = false;
          this.examAudioM3u8.shift();
        } else {
          this.currentAudio = -1;
        }
        this.lastDom = null;
      },
      onPlayerPlaying(player) {
        // console.log(`onPlayerPlaying ==> ${player}`);
      },
      onPlayerWaiting(player) {
        // console.log(`onPlayerWaiting ==> ${player}`);
      },
      onPlayerLoadeddata(player) {
        // console.log(`onPlayerLoadeddata ==> ${player}`);
      },
      onPlayerTimeupdate(player) {
        // console.log(`onPlayerTimeupdate ==> ${player}`);
      },
      onPlayerCanplay(player) {
        if (this.isShowHistory || this.isCheckAnswer || !this.isGetAudioToken || this.isPlayAudio || this.masterAudioPic || this.lastDom != "") {

        } else if (this.$JsBridge.isAndroid) {
          this.onPlay();
        }
      },
      onPlayerCanplaythrough(player) {
        // console.log(`onPlayerCanplaythrough ==> ${player}`);
      },
      // player is ready
      playerReadied(player) {
        // console.log('the player is readied', player)
        // you can use it to do something...
        // player.[methods]
        if (this.$JsBridge.isiOS) {
          setTimeout(this.onPlay, 300);
        }
      },
      onPlay(){
        if (this.$refs.videoPlayer.player != undefined) {
          this.$refs.videoPlayer.player.play();  
        }
        if (this.lastDom != undefined && this.lastDom != null && this.lastDom != "") {
          this.lastDom.src = "assets/img/play.png";
        }  
      },
      JumpToTop(){
        // 自動將畫面移動到最上方
        $("html, body").animate({ scrollTop: $("html").offset().top }, {duration: 500,easing: "swing"});
      },
      pauseVueAudio(){
        if (this.$refs.videoPlayer != undefined && this.$refs.videoPlayer.player != undefined) {
          this.$refs.videoPlayer.player.pause();
          if (this.lastDom != undefined && this.lastDom != null && this.lastDom != "") {
            this.lastDom.src = "assets/img/play_nostar.png";
          }
        }
      },
      showModal(target){
        $(target).modal("show");
      }
    },
    created(){
      if (this.$route.query.eplid != undefined) {
        this.eplid = this.$route.query.eplid;
      }
      if (this.$route.query.uid != undefined) {
        this.$GlobalData.uid = this.$route.query.uid;
      }
      if (this.$route.query.lsid != undefined) {
        this.lsid = this.$route.query.lsid;
      }
      if (this.$route.query.tryError != undefined) {
        this.tryError = this.$route.query.tryError;
      }
      if (this.$route.query.elid != undefined) {
        this.elid = this.$route.query.elid;
      }
      if (this.$route.query.egid != undefined) {
        this.egid = this.$route.query.egid;
      }
      if (this.$route.query.huepid != undefined) {
        this.huepid = this.$route.query.huepid;
      }
      if (this.$JsBridge.isAndroid) {
        this.setOnBack();
      }
      this.getAPI();

      this.$JsBridge.callHandler('setPauseVueAudio',JSON.stringify({backUrl:"ListeningExamStart", type:""}), this.pauseVueAudio);
    },
    mounted() { 
		  this.config = config;
    }
};
</script>
<style>
  @import '../../../../public/assets/css/admin-customize.css';
  .addedHight{
    height: 25px;
  }
  .hide{
    display: none !important;
  }
  .examItemSuccess{
    background-image: linear-gradient(to bottom, rgba(96, 255, 231, 0.4), rgba(96, 255, 231, 0.4));
  }
  .examItemError{
    background-image: linear-gradient(to bottom, rgba(255, 158, 158, 0.4), rgba(255, 158, 158, 0.4));
  }
  .examItemImage{
    background-size:contain; 
    background-repeat: no-repeat; 
    background-position:center;
  }
  .disabled{
    color: #fff !important;
    background-color: #c9c9c9 !important;
  }
</style>
<style scoped>
  body{
    color: #333;
    font-weight: 500;
    line-height: 2;
  }
  .badge-secondary{
    /*color: #6c757d;*/
    color: #43494e;
  }
  .bigbox3{
    margin: auto;
  }
  .text-s{
    font-size: 1.05rem !important;
  }
  @media only screen and (max-width: 1366px) and (orientation: landscape){
    .bigbox3{
      width: 60vw;
    }
  }
  @media only screen and (max-width: 1024px){
    .bigbox3{
      width: 68vw;
    }
    .red_img{
      width: 33vw !important;
    }
  }
  @media only screen and (max-width: 768px){
    .bigbox3{
      width: 70vw;
    }
    .red_img{
      width: 30px !important;
    }
  }
  @media only screen and (max-width: 480px){
    .bigbox3{
      width: 81vw;
    }
    .red_img{
      width: 6vw !important;
    }
  }
  @media only screen and (max-width: 320px){
  }
</style>

